<app-new-top-search></app-new-top-search>
<div class="relative h-full" style="zoom: 1.2">
	<img ngSrc="assets/notfound.webp" class="object-cover w-full h-full object-bottom" width="1332" height="942" />
	<div
		class="bg-white absolute top-1/2 md:top-1/2 -translate-y-1/2 md:left-1/2 lg:left-[inherit] md:-translate-x-1/2 lg:translate-x-0 lg:right-12 p-3 py-6 rounded-2xl center max-w-[550px] flex flex-col gap-3 light-shadow mx-3 md:mx-0"
	>
		<h1 class="text-mainYellow font-bold text-base md:text-lg lg:text-3xl text-center">
			Błąd 404 na mapie wakacji.
		</h1>
		<p class="text-mainColor text-center text-sm md:text-base lg:text-lg">
			Wszystkie wakacyjne drogi tu się gubią... Ale nie poddawaj się, szukajmy dalej!
		</p>
		<a href="/" class="w-3/4 text-center self-center">
			<app-button type="box-button-added">Wróć do Strony Głównej </app-button>
		</a>
	</div>
</div>
