<div
	class="flex flex-wrap h-auto md:w-[320px] md:h-full justify-center gap-x-3 lg:mx-2 shadow-[0px_10px_15px_2px_#00000010] rounded-[40px]"
>
	<div class="pb-[20px]">
		<div
			class="relative h-[280px] w-[320px] bg-auto rounded-tl-[40px] rounded-tr-[40px] bg-center mx-auto cursor-pointer overflow-hidden"
			(click)="goToOffer()"
		>
			<ng-container *ngIf="data.image; else noImage">
				<img
					#offerBoxImage
					[alt]="data.title"
					[ngSrc]="data.image | optimizedImage: { mode: 'optimize', element: offerBoxImage }"
					class="h-full object-cover"
					fill
					loading="lazy"
				/>
			</ng-container>
			<ng-template #noImage>
				<div class="h-full object-cover">
					<p
						class="flex justify-center items-center h-full border-mainColor border-1 rounded-tl-[40px] rounded-tr-[40px] font-bold text-mainColor"
					>
						Brak zdjęcia
					</p>
				</div>
			</ng-template>
			<img
				[ngSrc]="data.promotionImage | optimizedImage"
				height="30"
				width="120"
				alt="Promocja"
				*ngIf="data.promotionImage"
				class="absolute top-8 w-1/2"
				loading="lazy"
			/>

			<div class="absolute top-2 right-2 hover:cursor-pointer p-3 hidden">
				<i
					class="fa-heart transition text-3xl"
					[ngClass]="fav ? ' text-red fa-solid' : 'text-accentColor fa-regular'"
					(click)="favToggle()"
				></i>
			</div>
		</div>
		<div class="flex-grow p-2 text-mainColor px-[20px]">
			<div
				class="font-extrabold text-mainColor h-[30px] uppercase cursor-pointer overflow-ellipsis whitespace-nowrap max-w-[250px] overflow-hidden"
				(click)="goToOffer()"
			>
				{{ data.title }}
			</div>
			<div class="text-base text-mainColor h-[30px]">
				{{ data.location }}
			</div>
			<div>
				<div class="inline-block mt-2 min-w-18">
					<i class="fa-solid fa-calendar-days mr-2"></i>
					{{ data.date }}
				</div>
				<div class="flex mt-2 min-w-18 justify-between items-center">
					<span>
						<i class="fa-solid fa-utensils mr-2"></i>
						{{ data.food }}
					</span>
					<i
						class="fas fa-info-circle cursor-pointer hover:text-mainYellow transition-colors"
						(click)="displayMoreFoodInfo(data)"
						#boardsTooltip
					></i>
				</div>
				<div class="inline-block mt-2 min-w-18">
					<i class="fa-solid fa-bus mr-2"></i>
					{{ data.transport }}
				</div>
			</div>
		</div>
	</div>
	<div class="w-full">
		<div class="flex flex-col items-end justify-center w-full px-[20px]">
			<p
				class="text-mainColor"
				[ngClass]="{ 'line-through': data.price }"
				*ngIf="data.priceCatalog && data.priceCatalog !== data.price"
			>
				{{ data.priceCatalog | price }}/os
			</p>
			<p class="text-red text-lg font-extrabold">{{ data.price | price }}/os</p>
		</div>
		<div></div>
	</div>
	<a
		[href]="'../oferta/' + productId + '?' + getUrlParams()"
		(click)="handleButtonClick($event)"
		class="!rounded-3xl border-[1px] bg-accentColor hover:bg-[#ffce6a] justify-evenly text-white font-extrabold text-base md:text-lg py-2 my-3 uppercase px-4 text-center w-[80%]"
		>ZOBACZ OFERTĘ</a
	>
</div>
