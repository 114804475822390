import { Component, OnInit, ViewChild } from '@angular/core';

import { SwiperComponent } from 'swiper/angular';
import SwiperCore, { SwiperOptions, Navigation, Grid } from 'swiper';
import { DataService, Result } from '@/common/data.service';

SwiperCore?.use([Navigation, Grid]);

export interface DestinationInterface {
	id: number;
	location: {
		id: number;
		name: string;
		multimedia: [
			{
				id: number;
				multimedia: {
					id: string;
					name: string;
					url: string;
				};
				order: number;
			},
		];
	};
	order: number;
}

@Component({
	selector: 'app-main-destinations',
	templateUrl: './main-destinations.component.html',
	styleUrls: ['./main-destinations.component.css'],
})
export class MainDestinationsComponent implements OnInit {
	destinations: Array<DestinationInterface> = [];

	swiperConfig: SwiperOptions = {
		slidesPerView: 2.2,
		spaceBetween: 10,
		lazy: true,
		breakpoints: {
			'640': {
				slidesPerView: 1,
				spaceBetween: 5,
			},
			'1024': {
				slidesPerView: 3,
				spaceBetween: 10,
			},
			'1440': {
				slidesPerView: 3,
				direction: 'horizontal',
				grid: { rows: 2, fill: 'row' },
			},
		},
	};

	@ViewChild(SwiperComponent)
	swiper!: SwiperComponent;

	constructor(private readonly bag: DataService) {}

	ngOnInit(): void {
		this.bag.get('www/destination/', (result: Result<DestinationInterface>) => {
			this.destinations = result.results;
		});
	}

	swipePrev(): void {
		this.swiper.swiperRef.slidePrev();
	}

	swipeNext(): void {
		this.swiper.swiperRef.slideNext();
	}
}
