// counter.effects.ts
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { combineLatest, of, tap, withLatestFrom } from 'rxjs';
import { OffersApiService } from '@/_api/offers.api.service';
import * as offersActions from './offers.actions';
import {
	ApiOfferFilter,
	ApiOfferList,
	DEFAULT_PAGINATION_LIMIT,
	DEFAULT_PAGINATION_OFFSET,
	Selected,
} from '@/_store/offers/offers.types';
import { Result } from '@/common/data.service';
import {
	convertToOfferList,
	createOfferListApiParams,
	createQueryParams,
	getOffsetFromNext,
	setSelectedValueBaseOnParams,
} from '@/_store/offers/offers.utils';
import { arrayToObject } from '@/_utils/arrays/array-to-object';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStorageService } from '@/common/sesstionstorage.service';
import { Nights } from '@/new-top-search/top-search-dropdown-item/top-search-dropdown-item.types';
import { Store } from '@ngrx/store';
import { selectSorting } from '@/_store/offers/offers.selectors';

@Injectable()
export class OffersEffects {
	constructor(
		private readonly actions$: Actions,
		private readonly offersApiService: OffersApiService,
		private readonly activateRoute: ActivatedRoute,
		private readonly router: Router,
		private readonly sessionStorageService: SessionStorageService,
	) {}

	fetchOfferFilter$ = createEffect(() =>
		this.actions$.pipe(
			ofType(offersActions.fetchOfferFilter),
			switchMap(() =>
				this.offersApiService.getOfferFilter().pipe(
					map((result: ApiOfferFilter) =>
						offersActions.fetchOfferFilterSuccess({
							filterOptions: {
								...result,
								nights: {
									min: result.nights,
									max: result.nights,
								},
								defaultNights: result.nights,
							},
						}),
					),
					catchError((error) =>
						of(
							offersActions.fetchOfferFilterError({
								error,
							}),
						),
					),
				),
			),
		),
	);

	fetchOfferList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(offersActions.fetchOffers),
			withLatestFrom(inject(Store).select(selectSorting)),
			switchMap(([{ params, paramsForNextPage }, sorting]) => {
				const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

				return combineLatest([
					// We perform two requests to download next page with offers,
					// so that when you click show more, you can immediately display the data
					this.offersApiService.getOffers(params + `&ordering=${sorting}`, timezone),
					this.offersApiService.getOffers(paramsForNextPage + `&ordering=${sorting}`, timezone),
				]).pipe(
					switchMap(([result, resultForNextPage]) =>
						of(
							offersActions.fetchOffersSuccess({
								offersList: convertToOfferList(result.results),
							}),
							offersActions.updateOffset({ offset: getOffsetFromNext(resultForNextPage.next) }),
							offersActions.updateCount({ count: result.count }),
							offersActions.updateNextOffers({
								nextPage: result.next ? convertToOfferList(resultForNextPage.results) : null,
							}),
						),
					),
					catchError((error) =>
						of(
							offersActions.fetchOffersError({
								error,
							}),
						),
					),
				);
			}),
		),
	);

	fetchNextOfferList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(offersActions.fetchNextOffers),
			withLatestFrom(inject(Store).select(selectSorting)),
			switchMap(([{ params }, sorting]) =>
				this.offersApiService
					.getOffers(params + `&ordering=${sorting}`, Intl.DateTimeFormat().resolvedOptions().timeZone)
					.pipe(
						switchMap((result: Result<ApiOfferList>) =>
							of(
								offersActions.updateNextPageIsLoading({ nextPageIsLoading: false }),
								offersActions.updateOffset({ offset: getOffsetFromNext(result.next) }),
								offersActions.fetchNextOffersSuccess({
									nextPage: convertToOfferList(result.results),
								}),
							),
						),
						catchError((error) =>
							of(
								offersActions.fetchNextOffersError({
									error,
								}),
							),
						),
					),
			),
		),
	);

	fetchBoards$ = createEffect(() =>
		this.actions$.pipe(
			ofType(offersActions.fetchBoards),
			switchMap(({ id }) =>
				this.offersApiService.getBoards(id).pipe(
					map((result: string[]) =>
						offersActions.fetchBoardsSuccess({
							boards: arrayToObject(result),
						}),
					),
					catchError((error) =>
						of(
							offersActions.fetchBoardsError({
								error,
							}),
						),
					),
				),
			),
		),
	);

	updateOfferFilter$ = createEffect(() =>
		this.actions$.pipe(
			ofType(offersActions.fetchOfferFilterSuccess),
			withLatestFrom(this.activateRoute.queryParams),
			switchMap(([{ filterOptions }, queryParams]) => {
				const selected: Selected = setSelectedValueBaseOnParams(filterOptions, queryParams);
				const paramsFromSelected = createOfferListApiParams(selected);
				return of(
					offersActions.updateSelectedValues({ selected }),
					offersActions.updateNightsOptions({
						option: {
							value: selected.nights.min,
							type: Nights.FROM,
						},
					}),
					offersActions.updateNightsOptions({
						option: { value: selected.nights.max, type: Nights.TO },
					}),
					offersActions.updateOffset({ offset: DEFAULT_PAGINATION_OFFSET }),
					offersActions.updateQueryParams({
						params: paramsFromSelected,
					}),
					offersActions.fetchOffers({
						params:
							paramsFromSelected +
							`&limit=${DEFAULT_PAGINATION_LIMIT}&offset=${DEFAULT_PAGINATION_OFFSET}`,
						paramsForNextPage:
							paramsFromSelected +
							`&limit=${DEFAULT_PAGINATION_LIMIT}&offset=${DEFAULT_PAGINATION_OFFSET + DEFAULT_PAGINATION_LIMIT}`,
					}),
				);
			}),
		),
	);

	updateQueryParams$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(offersActions.updateQueryParams),
				tap(({ params }) => {
					const queryParams = createQueryParams(params);
					this.router.navigate([], {
						relativeTo: this.activateRoute,
						queryParams,
					});
				}),
			),
		{ dispatch: false },
	);
}
