import { ButtonModule } from '@/modules/shared/button/button.module';
import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BehaviorSubject, Subject, catchError, delay, filter, finalize, of, takeUntil } from 'rxjs';
import { NewsletterStatusesMap } from './newsletter-statuses';
import { NewsletterApiService } from './newsletter.api.service';
import { LoaderSunComponent } from '../loader-sun/loader-sun.component';

@Component({
	selector: 'app-newsletter',
	standalone: true,
	imports: [ReactiveFormsModule, NgClass, ButtonModule, AsyncPipe, LoaderSunComponent],
	providers: [NewsletterApiService],
	templateUrl: './newsletter.component.html',
	styleUrl: './newsletter.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsletterComponent implements OnInit {
	newsletterFormGroup = new FormGroup({
		email: new FormControl('', [Validators.required, Validators.email]),
		consent: new FormControl(false, [Validators.requiredTrue]),
	});

	showError$ = new BehaviorSubject(false);
	response = signal<string>(null);
	loading = signal(false);

	private readonly destroy$ = new Subject<void>();

	private readonly newsletterApiService = inject(NewsletterApiService);

	ngOnInit(): void {
		this.showError$
			.pipe(
				takeUntil(this.destroy$),
				filter((showError) => showError),
				delay(3000),
			)
			.subscribe(() => {
				this.showError$.next(false);
			});
	}

	signUpToNewsletter(): void {
		if (!this.validate()) {
			return;
		}

		this.loading.set(true);

		this.newsletterApiService
			.signUpToNewsletter(this.newsletterFormGroup.controls.email.value)
			.pipe(
				catchError(({ error }) => {
					if (error && error.errors && error.errors.length) {
						const code = error.errors[0].code;
						const message = NewsletterStatusesMap.hasOwnProperty(code)
							? NewsletterStatusesMap[code]
							: 'Wystąpił błąd';
						this.response.set(message);
					}
					return of(null);
				}),
				filter((value) => !!value),
				finalize(() => this.loading.set(false)),
			)
			.subscribe(() => {
				this.response.set('E-mail został zapisany pomyślnie do newslettera');
			});
	}

	private validate(): boolean {
		this.newsletterFormGroup.markAllAsTouched();

		if (this.newsletterFormGroup.invalid) {
			this.triggerShowError();
			return false;
		}

		return true;
	}

	private triggerShowError(): void {
		this.showError$.next(true);
	}
}
