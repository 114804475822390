import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from '@/app.module';
import { environment } from './environments/environment';

if (environment.production) {
	enableProdMode();
}

const HOSTNAMES_TO_INCLUDE = ['oskar.com.pl', 'dev.oskar.com.pl'];
if (HOSTNAMES_TO_INCLUDE.includes(window.location.hostname)) {
	const getConfig = () => {
		return environment.production
			? {
					environment: 'prod',
					replaysSessionSampleRate: 0.1,
					tracesSampleRate: 0.1,
					replaysOnErrorSampleRate: 1,
				}
			: {
					environment: 'dev',
					replaysSessionSampleRate: 1,
					tracesSampleRate: 0.5,
					replaysOnErrorSampleRate: 1,
				};
	};

	const networkDetailAllowUrls = [window.location.origin];

	if (!environment.production) {
		networkDetailAllowUrls.push(environment.apiUrl);
		environment.erpApiUrl ?? networkDetailAllowUrls.push(environment.erpApiUrl);
	}

	Sentry.init({
		...getConfig(),
		dsn: 'https://1ca66f67406a4ee8955af31dc7e64bc7@o4505443056287744.ingest.sentry.io/4505526828466176',
		integrations: [
			new Sentry.BrowserTracing({
				tracingOrigins: networkDetailAllowUrls,
				routingInstrumentation: Sentry.routingInstrumentation,
				tracePropagationTargets: [],
			}),
			new Sentry.Replay({
				networkDetailAllowUrls,
				networkDetailDenyUrls: [/.*\/token\/.*/gm], // ignore displaying details of auth request to prevent from showing user password in request payload
				maskAllText: false,
				maskAllInputs: false,
			}),
		],
	});
}

function bootstrap() {
	platformBrowserDynamic()
		.bootstrapModule(AppModule)
		.catch((err) => console.error(err));
}

if (document.readyState === 'complete') {
	bootstrap();
} else {
	document.addEventListener('DOMContentLoaded', bootstrap);
}
