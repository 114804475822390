import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthBagService } from '../../auth/auth-bag.service';
import { MainService } from '../main.service';
import { DateService } from '@/common/date.service';
import { DataService } from '@/common/data.service';
import { ParamsSplit } from '@/common/paramsSplit.service';
import { OfferBox } from '@/common/offer-box/offer-box.model';
@Component({
	selector: 'app-main-common',
	template: '',
})
export class MainCommonComponent implements OnInit {
	@Input() title = '';
	@Input() query = '';
	@Input() link?: string;
	protected data: OfferBox[] = [];

	constructor(
		public bag: DataService,
		public paramsSplit: ParamsSplit,
		public router: Router,
		public auth: AuthBagService,
		protected service: MainService,
		public dateService: DateService,
	) {}

	ngOnInit(): void {
		console.log('Pobierz dane oferty z API');
	}
}
