export class OfferBox {
	productId = 0;
	promotionImage: string | undefined = '';
	image = '';
	label?: string | null = '';
	title = '';
	location = '';
	date = '';
	food = '';
	nights = '';
	transport = '';
	discounts?: string[] | null = [];
	price = 0;
	priceCatalog?: number | null = 0;
	pricePerson?: string | null = null;
	params = {
		stop: 0,
		roomType: {
			code: '',
			id: 0,
			name: '',
		},
		adults: 0,
		children: 0,
		stopAddres: '',
		dateRange: ``,
		boards: 0,
		promotion: 0,
	};
}
