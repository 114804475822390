<mat-dialog-content class="relative">
	<i class="fas fa-close absolute top-2 right-2 text-mainColor cursor-pointer" (click)="closeDialog()"></i>
	<h3 class="text-sm md:text-base text-mainColor font-bold flex justify-between items-center gap-3">
		Dostępne opcje wyżywienia:
	</h3>
	<p *ngIf="!(availableBoards$ | async)?.length">Ładowanie danych...</p>
	<div class="text-mainColor text-sm">
		<p *ngFor="let board of availableBoards$ | async">{{ board }}</p>
	</div>
</mat-dialog-content>
