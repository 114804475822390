import { isPlatformServer } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';
import { Observable, timeout } from 'rxjs';

export const HTTP_CLIENT_SSR_TIMEOUT = new InjectionToken<number>('HTTP_CLIENT_SSR_TIMEOUT');

@Injectable()
export class HttpTimeoutInterceptor implements HttpInterceptor {
	constructor(
		@Inject(PLATFORM_ID) private readonly platformId: typeof PLATFORM_ID,
		@Inject(HTTP_CLIENT_SSR_TIMEOUT) private readonly httpSsrTimeout: number,
	) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (isPlatformServer(this.platformId)) {
			return next.handle(request).pipe(timeout(this.httpSsrTimeout));
		}
		return next.handle(request);
	}
}
