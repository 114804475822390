import { Component, Inject, Input } from '@angular/core';
import { Params, Router } from '@angular/router';
import { OfferBox } from './offer-box-mobile-highlight.model';
import { DOCUMENT } from '@angular/common';
import { Data } from '../../offer/offer.model';
import { removeLastChar } from '@/_utils/strings/remove-last-char';

@Component({
	selector: 'app-offer-box-mobile-highlight',
	templateUrl: './offer-box-mobile-highlight.component.html',
	styleUrls: ['./offer-box-mobile-highlight.component.css'],
})
export class OfferBoxMobileHighlightComponent {
	@Input() data = new OfferBox();
	@Input() dataToTransfer!: Data;

	constructor(
		public router: Router,
		@Inject(DOCUMENT) protected document: Document,
	) {}

	goToOffer(): void {
		const params: Params = this.getUrlParams('object') as Params;

		this.router.navigate([`../oferta/${this.data.productId}`], {
			queryParams: Object.assign({}, params),
		});
	}

	protected getUrlParams(target: 'url' | 'object' = 'url'): Params | string {
		let roomType;
		const daterange = this.data.params.dateRange;
		if (!this.data.params.roomType) {
			roomType = '';
		} else {
			roomType = this.data.params.roomType.id;
		}
		const adults = this.data.params.adults;
		const children = this.data.params.children;
		const ownTransport = !this.data.params.stopAddres;
		const params = {
			boards: this.data.params.boards,
			promotion_catalogs: this.data.params.promotion,
			room_type: roomType,
			stops: this.data.params.stop,
			date_range: daterange,
			own_transport: ownTransport,
			adults: adults,
			children: children,
			nights: this.dataToTransfer.room.term.nightsStay,
		};
		let url = '';
		for (const [key, value] of Object.entries(params)) {
			url = url.concat(`${key}=${value}&`);
		}

		url = removeLastChar(url);

		return target === 'url' ? url : params;
	}

	protected getOfferUrl(): string {
		return `/oferta/${this.data.productId}`;
	}

	protected handleButtonClick(event: Event): void {
		event.preventDefault();
		event.stopPropagation();

		this.goToOffer();
	}
}
