<app-main-header [link]="link" class="mt-10"
	><h1 class="pl-5 lg:pl-0 text-lg lg:text-3xl mb-[10px]">
		<span class="text-accentColor">{{ title }}</span
		><span class="text-mainColor"> {{ titleSuffix }}</span>
	</h1>
	<!--	<a-->
	<!--		class="hidden md:visible text-mainColor lg:text-base hover:cursor-pointer hover:text-accentColor transition"-->
	<!--		>Zobacz więcej w tym terminie-->
	<!--		<i class="fa-solid fa-chevron-right"></i> </a-->
	<!--&gt;-->
</app-main-header>
<ng-container *ngIf="!dataLoaded; else allDataLoaded">
	<div class="flex flex-col justify-center items-center p-24">
		<h1 class="text-mainColor font-bold text-2xl">Wczytujemy dane, prosimy o cierpliwość :)</h1>
		<i class="fa-solid fa-sun animate-spin-slow text-accentColor text-3xl p-4"></i>
	</div>
</ng-container>
<ng-template #allDataLoaded>
	<div class="flex flex-col gap-3 md:gap-5">
		<a
			class="group flex w-auto flex-nowrap justify-between transition rounded-[17px] py-3 px-2 mx-2 hover:bg-mainColor border-accentColor bg-accentColor hover:cursor-pointer shadow-[0px_10px_15px_2px_#00000010]"
			*ngFor="let item of data; let i = index"
			[href]="item?.href"
			(click)="handleButtonClick($event, i)"
		>
			<div
				class="w-1/2 text-sm ml-3 lg:w-3/12 flex items-center font-extrabold transition group-hover:text-white text-white"
			>
				{{ item.title }}
			</div>
			<div class="hidden lg:flex lg:w-7/12 justify-between text-white group-hover:text-white font-extrabold">
				<div class="flex items-center w-1/3 font-thin">
					{{ locationShort(item.location) }}
				</div>
				<div class="flex items-center w-1/3">
					<i class="fa-solid fa-utensils"></i>
					<span class="pl-2">{{ item.food }}</span>
					<i
						class="fas fa-info-circle cursor-pointer ml-3 hover:text-mainYellow transition-colors"
						(click)="displayMoreFoodInfo($event, item)"
						#boardsTooltip
					></i>
				</div>
				<div class="flex items-center w-1/3">
					{{ item.date }}
				</div>
			</div>
			<div class="flex items-center justify-center">
				<div
					class="flex items-center w-full text-sm font-extrabold text-mainColor px-3 group-hover:text-mainYellow"
				>
					<span class="pl-2">od {{ item.price | price }}/os</span>
				</div>
				<div
					class="hidden lg:flex gap-3 justify-end content-around items-center flex-grow cursor-pointer"
					(click)="fav = !fav"
				>
					<div class="flex items-center favorite text-xl group-hover:text-white text-accentColor hidden">
						<i
							class="fa-heart group-hover:text-white"
							[ngClass]="fav ? ' text-red fa-solid' : 'text-accentColor fa-regular'"
						></i>
					</div>
				</div>
			</div>
		</a>
	</div>
</ng-template>
