<div class="px-2 h-[auto] mb-10">
	<app-main-header [link]="link">
		<h1 class="text-lg md:text-3xl mb-[10px]">
			<span class="text-accentColor">{{ title }}</span
			><span class="text-mainColor"> {{ titleSuffix }}</span>
		</h1></app-main-header
	>
	<div *ngIf="!dataLoaded" class="flex flex-col justify-center items-center p-24">
		<h1 class="text-mainColor font-bold text-2xl">Wczytujemy dane, prosimy o cierpliwość :)</h1>
		<i class="fa-solid fa-sun animate-spin-slow text-accentColor text-3xl p-4"></i>
	</div>
	<div *ngIf="dataLoaded" class="flex flex-row items-center pb-3 md:pb-0">
		<button (click)="swipePrev()" aria-label="Pokaż poprzednią ofertę" class="hidden lg:block">
			<i class="fa-solid fa-chevron-left text-accentColor text-3xl"></i>
		</button>
		<swiper [config]="swiperConfig" class="pb-[35px] md:pb-0">
			<ng-template swiperSlide *ngFor="let item of data; let i = index" class="md:flex md:justify-center">
				<app-mobile-wrapper>
					<app-offer-box-mobile
						[data]="item"
						[dataToTransfer]="dataToTransfer[i]"
						class="md:hidden"
					></app-offer-box-mobile>
				</app-mobile-wrapper>
				<app-offer-box
					[data]="item"
					[dataToTransfer]="dataToTransfer[i]"
					class="hidden md:block h-full pb-[30px]"
				></app-offer-box>
			</ng-template>
		</swiper>
		<button (click)="swipeNext()" aria-label="Pokaż następną ofertę" class="hidden lg:block">
			<i class="fa-solid fa-chevron-right text-accentColor text-3xl"></i>
		</button>
	</div>
</div>
