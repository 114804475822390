import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
	selector: 'app-custom-select',
	templateUrl: './custom-select.component.html',
	styleUrls: ['./custom-select.component.css'],
})
export class CustomSelectComponent implements OnInit {
	@Input() options: string[] = [];
	@Input() tabindex = 0;
	@Output() outputSelectedOption = new EventEmitter<string>();

	selectedValue = '';

	protected isOpen = false;

	constructor(@Inject(DOCUMENT) private readonly document: Document) {}

	ngOnInit(): void {
		if (this.options && this.options.length > 0) {
			this.selectedValue = this.options[0];
			this.outputSelectedOption.emit(this.options[0]);
		}
	}

	@HostListener('document:click', ['$event.target'])
	outsideClick(targetElement: HTMLElement): void {
		this.isOpen = !!this.document.querySelector('.customSelect')?.contains(targetElement);
	}

	protected toggleDropdown(): void {
		this.isOpen = !this.isOpen;
	}

	protected selectOption(option: string): void {
		this.selectedValue = option;
		this.outputSelectedOption.emit(option);
		this.isOpen = false;
	}
}
