<app-navi></app-navi>
<app-availability-error></app-availability-error>

<router-outlet class="bg-[#f0f0f0]"></router-outlet>

<app-footer></app-footer>

<div *ngIf="isLoggedIn$ | async" class="fixed print:hidden top-0 right-0 p-3 mr-5">
	Zalogowany sprzedawca
	<button (click)="logout()">Wyloguj</button>
</div>

<div id="fb-root"></div>
<noscript>
	<iframe [src]="googleTagManagerUrl | trustUrl" height="0" width="0" class="hidden invisible"></iframe>
</noscript>
