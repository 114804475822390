import { Component, OnInit } from '@angular/core';
import { DataService, Result } from '@/common/data.service';
import { NaviService, Position } from './navi.service';

@Component({
	selector: 'app-navi',
	templateUrl: './navi.component.html',
	styleUrls: ['./navi.component.css'],
})
export class NaviComponent implements OnInit {
	constructor(
		private readonly data: DataService,
		private readonly service: NaviService,
	) {}

	ngOnInit(): void {
		this.data.get('www/menu_www/', (result: Result<Position>) => {
			this.service.list.next(result.results);
		});
	}
}
