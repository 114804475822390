<div
	class="group relative h-[200px] w-[80vw] lg:h-[270px] bg-auto rounded-[40px] bg-center mx-auto overflow-hidden cursor-pointer shadow-[0px_10px_15px_2px_#00000010]"
>
	<a
		[href]="'../oferta/' + data.productId + '?' + getUrlParams()"
		(click)="handleButtonClick($event)"
		class="shadow-[0px_10px_15px_2px_#00000010]"
	>
		<div
			class="w-full h-full rounded-[40px] bg-center bg-cover md:group-hover:scale-110 overflow-hidden transition"
		>
			<ng-container *ngIf="data.image; else noImage">
				<img
					#offerBoxMobileHighlightImage
					[ngSrc]="data.image | optimizedImage: { mode: 'optimize', element: offerBoxMobileHighlightImage }"
					fill
					loading="lazy"
					[alt]="data.title"
				/>
			</ng-container>
			<ng-template #noImage>
				<p
					class="w-full text-center h-full flex justify-center items-center border-mainColor border-1 rounded-[40px] text-mainColor font-bold"
				>
					Brak zdjęcia
				</p>
			</ng-template>
		</div>
		<div
			class="flex flex-col md:flex-row justify-between md:block absolute bottom-6 left-6 p-3 w-[70%] rounded-[15px] bg-white/70"
		>
			<p
				class="text-mainColor text-sm font-bold uppercase mb-2 overflow-ellipsis whitespace-nowrap overflow-hidden"
			>
				{{ data.title }}
			</p>
			<p class="text-mainColor text-base font-bold">od {{ data.price | price }}/os</p>
		</div>
		<div class="bg-accentColor">
			<p>{{ data.label }}</p>
		</div>
	</a>
</div>
