import { Injectable } from '@angular/core';
import { AuthBagService } from '../auth/auth-bag.service';

@Injectable({
	providedIn: 'root',
})
export class MainService {
	constructor(private readonly auth: AuthBagService) {}

	/**
	 * If user is logged in then display every offer, even those which are supposed to be hidden. The not logged users (clients by default) won't see them.
	 * @param offers
	 */
	// eslint-disable-next-line  @typescript-eslint/no-explicit-any
	getOffers(offers: any[]): any[] {
		return this.auth.isLogged ? offers : this.getNotHiddenOffers(offers);
	}

	// eslint-disable-next-line  @typescript-eslint/no-explicit-any
	private getNotHiddenOffers(offers: any[]): any[] {
		return offers?.filter(
			(offer) =>
				// eslint-disable-next-line  @typescript-eslint/no-explicit-any
				offer.room.term.product.tags.some((tag: any) => tag.tag !== 'hidden') ||
				!offer.room.term.product.tags.length,
		);
	}
}
