import { isPlatformServer } from '@angular/common';
import { Component, Inject, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Response } from 'express';
import { RESPONSE } from '../express.token';

@Component({
	selector: 'app-not-found',
	templateUrl: './not-found.component.html',
	styleUrls: ['./not-found.component.css'],
})
export class NotFoundComponent implements OnInit {
	constructor(
		private readonly meta: Meta,
		private readonly title: Title,
		@Optional() @Inject(RESPONSE) private readonly response: Response,
		@Inject(PLATFORM_ID) private readonly platformId: string,
	) {}

	ngOnInit(): void {
		if (this.response && isPlatformServer(this.platformId)) {
			this.response.status(404);
		}
		this.title.setTitle('Ups... Nie znaleziono strony');
		this.meta.addTag({ name: 'robots', content: 'noindex' });
	}
}
