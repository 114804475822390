import { Injectable } from '@angular/core';
import { DataService, Result } from '@/common/data.service';
import { BannerImage } from './banner-carousel.component';

@Injectable({
	providedIn: 'root',
})
export class BannerCarouselService {
	constructor(private readonly dataService: DataService) {}

	loadImages(): Promise<Result<BannerImage>> {
		return new Promise((resolve, reject) => {
			this.dataService.get('www/banner_www/', (result: Result<BannerImage>, error: Error) => {
				if (result) resolve(result);
				else reject(error);
			});
		});
	}
}
