import { AppState } from './app-state.interface';
import { STORAGE_STATE_KEY } from './local-storage-state-key';
import { initialState as currentOffer } from './current-offer/current-offer.reducer';
import { initialState as general } from './general/general.reducer';
import { initialState as offers } from './offers/offers.reducer';

export function getInitialAppState(): AppState {
	if (typeof sessionStorage === 'undefined') {
		return { currentOffer, general, offers };
	}

	const previousSettings = sessionStorage.getItem(STORAGE_STATE_KEY);
	if (previousSettings !== null) {
		return JSON.parse(previousSettings);
	}
	return { currentOffer, general, offers };
}
