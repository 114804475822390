import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Injectable()
export class UpdateService {
	constructor(private readonly updates: SwUpdate) {
		this.updates.versionUpdates.subscribe((event) => {
			if (event.type != 'VERSION_READY') {
				return;
			}
			window.location.reload();
		});
	}
}
