import { Component, Input, OnInit, ViewChild } from '@angular/core';

import SwiperCore, { SwiperOptions, Navigation, Grid } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

import { MainCommonComponent } from '../main-common/main-common.component';
import { OfferBox } from '../../common/offer-box/offer-box.model';
import { Data } from '../../offer/offer.model';
import { Result } from '@/common/data.service';

SwiperCore?.use([Navigation, Grid]);

@Component({
	selector: 'app-main-highlighted',
	templateUrl: './main-highlighted.component.html',
	styleUrls: ['./main-highlighted.component.css'],
})
export class MainHighlightedComponent extends MainCommonComponent implements OnInit {
	@Input() titleSuffix?: string;
	dataLoaded = false;
	dataToTransfer: Data[] = [];
	swiperConfig: SwiperOptions = {
		slidesPerView: 1.2,
		spaceBetween: 25,
		lazy: true,
		breakpoints: {
			'640': {
				slidesPerView: 1,
				spaceBetween: 5,
			},
			'1024': {
				slidesPerView: 3,
				spaceBetween: 10,
			},
			'1440': {
				slidesPerView: 3,
				direction: 'horizontal',
				grid: { rows: 2, fill: 'row' },
			},
		},
	};

	@ViewChild(SwiperComponent)
	swiper!: SwiperComponent;

	swipePrev(): void {
		this.swiper.swiperRef.slidePrev();
	}

	swipeNext(): void {
		this.swiper.swiperRef.slideNext();
	}

	override ngOnInit(): void {
		this.dataLoaded = false;
		this.bag.get(
			`www/offer/?${this.paramsSplit.splitParams(this.query)}&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
			(result: Result<Data>) => {
				if (result) this.dataToTransfer = result.results;
				this.data = this.service
					.getOffers(result.results)
					// eslint-disable-next-line  @typescript-eslint/no-explicit-any
					.map((offer: any) => {
						if (result)
							return {
								promotionImage: offer.promotion?.catalog.multimedia,
								productId: offer.room.term.product.id,
								image: offer.room.term.product.multimedias[0]?.multimedia.url,
								label: 'First minute',
								title: offer.room.term.product.name,
								location: offer.room.term.product.locations[0].name,
								date: `${offer.stop.departureDateTime} - ${offer.stop.returnDateTime}`,
								food: offer.board.name,
								nights:
									offer.room?.term.nightsStay == 0
										? `Bez noclegu`
										: `${offer.room?.term.nightsStay} nocy`,
								transport: 'Autokarem',
								discounts: ['-20%', '-100 PLN'],
								price: offer.fullPrice,
								priceCatalog: offer.catalogPrice,
								params: {
									stop: offer.stop.stop,
									roomType: offer.room.roomType,
									adults: offer.room.adults,
									children: offer.room.children,
									stopAddres: offer.stop.address,
									dateRange: `${offer.room.term.dateRange.lower},${offer.room.term.dateRange.upper}`,
									boards: offer.board.board,
									promotion: offer.promotion?.catalog.id ? offer.promotion.catalog.id : '',
								},
							};
						return <OfferBox>{};
					})
					.slice(0, 10);
				this.dataLoaded = true;
			},
		);
	}
}
