import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-success-footer-dialog',
	templateUrl: './success-footer-dialog.component.html',
	styleUrls: ['./success-footer-dialog.component.css'],
})
export class SuccessFooterDialogComponent {
	constructor(
		public dialog: MatDialogRef<SuccessFooterDialogComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: { header: string; message: string },
	) {}
}
