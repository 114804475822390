import { Component, Input } from '@angular/core';

interface seatType {
	bar: boolean;
	corridor: boolean;
}

@Component({
	selector: 'app-bus-seat',
	templateUrl: './bus-seat.component.html',
	styleUrls: ['./bus-seat.component.css'],
})
export class BusSeatComponent {
	@Input()
	type!: seatType;
}
