import { createReducer, on } from '@ngrx/store';
import * as generalActions from './general.actions';
import { Platform } from './general.types';

export interface GeneralState {
	isLoggedIn: boolean;
	platform: Platform;
}

export const initialState: GeneralState = {
	isLoggedIn: false,
	platform: 'desktop',
};

export const generalReducer = createReducer(
	initialState,
	on(generalActions.updateIsAuthenticated, (state, { isLoggedIn }) => ({
		...state,
		isLoggedIn,
	})),
	on(generalActions.updatePlatform, (state, { platform }) => ({
		...state,
		platform,
	})),
);
