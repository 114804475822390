import { Component, OnInit } from '@angular/core';
import SwiperCore, { SwiperOptions, Pagination, Navigation, Autoplay, EffectFade } from 'swiper';
import { TransferState } from '@angular/core';
import { IMAGES_KEY } from '@/main/main.guard';
import { BannerCarouselService } from './banner-carousel.service';
import { DeviceDetectorService } from 'ngx-device-detector';

SwiperCore?.use([Navigation, Pagination, Autoplay, EffectFade]);

export interface Image {
	path: string;
	url: string;
	text: ImageText;
}

export interface BannerImage {
	id: number;
	name: string;
	text: string;
	link: string;
	multimedia: {
		id: string;
		name: string;
		url: string;
	};
	multimediaMobile?: {
		id: string;
		name: string;
		url: string;
	};
}

export interface ImageText {
	label: string;
	style: ImageTextStyle;
}

export interface ImageTextStyle {
	color: string;
	fontSize: string;
	textShadow: string;
	fontFamily: string;
}

export const DELAY_ON_SWIPER = 3000;

@Component({
	selector: 'app-banner-carousel',
	templateUrl: './banner-carousel.component.html',
	styleUrls: ['./banner-carousel.component.css'],
})
export class BannerCarouselComponent implements OnInit {
	swiperConfig: SwiperOptions = {
		slidesPerView: 1,
		spaceBetween: 10,
		navigation: true,
		pagination: {
			clickable: true,
			renderBullet: () => {
				return '<span class="swiper-pagination-bullet"></span>';
			},
		},
		breakpoints: {
			'1024': {
				slidesPerView: 1,
				spaceBetween: 10,
			},
			'1440': {
				slidesPerView: 1,
				spaceBetween: 10,
			},
		},
		autoplay: {
			delay: DELAY_ON_SWIPER,
		},
		effect: 'fade',
		fadeEffect: { crossFade: true },
	};
	background: Image = {
		path: '',
		url: '',
		text: {
			label: '',
			style: {
				color: '',
				fontSize: '',
				textShadow: '',
				fontFamily: '',
			},
		},
	};
	selectedIndex = 0;
	images: Image[] = [];

	constructor(
		private readonly transferState: TransferState,
		private readonly service: BannerCarouselService,
		private readonly deviceService: DeviceDetectorService,
	) {}

	ngOnInit(): void {
		const mapImages = (images: BannerImage[]) => {
			return images.map((obj: BannerImage) => {
				return {
					path:
						this.deviceService.isMobile() && obj.multimediaMobile
							? obj.multimediaMobile.url
							: obj.multimedia.url,
					url: obj.link,
					text: {
						label: '',
						style: {
							color: '',
							fontFamily: '',
							fontSize: '',
							textShadow: '',
						},
					},
				};
			});
		};

		const images = this.transferState.get(IMAGES_KEY, null);
		if (images) {
			this.images = mapImages(images);
		} else {
			this.service.loadImages().then((result) => {
				this.images = mapImages(result.results);
			});
		}
	}

	select(index: number): void {
		this.selectedIndex = index;
		this.background = this.images[this.selectedIndex];
	}
}
