<button
	(keyup.escape)="dialog.close()"
	(click)="dialog.close()"
	class="absolute text-2xl right-4 top-2 text-mainColor"
	aria-label="Zamknij"
>
	<i class="fas fa-times"></i>
</button>
<div class="text-mainColor h-full justify-center px-16 py-5 flex flex-col items-center">
	<h1 class="text-mainYellow font-extrabold text-2xl pb-5">
		{{ data.header }}
	</h1>
	<p class="w-80 text-center pb-5">
		{{ data.message }}
	</p>
	<app-button (click)="dialog.close()" type="box-button-added">Zamknij</app-button>
</div>
