<app-main-header class="font-extrabold mt-10"
	><h1 class="text-lg lg:text-3xl">
		<span class="text-accentColor">Nasze </span><span class="text-mainColor">Kierunki</span>
	</h1>
	<!--	<a-->
	<!--		class="text-sm text-mainColor md:text-base hover:cursor-pointer hover:text-accentColor transition p-3 hidden"-->
	<!--	>-->
	<!--		zobacz wszystkie <i class="fa-solid fa-chevron-right"></i> </a>-->
</app-main-header>
<div class="lg:hidden flex flex-row items-center">
	<button (click)="swipePrev()" class="hidden lg:block">
		<i class="fa-solid fa-chevron-left text-accentColor text-3xl"></i>
	</button>

	<swiper [config]="swiperConfig" class="pb-[20px]">
		<ng-template swiperSlide *ngFor="let item of this.destinations">
			<app-destination-box class="flex items-center justify-center" [data]="item"></app-destination-box>
		</ng-template>
	</swiper>

	<button (click)="swipeNext()" class="hidden lg:block">
		<i class="fa-solid fa-chevron-right text-accentColor text-3xl"></i>
	</button>
</div>
<div
	class="hidden md:block w-full h-auto flex-col lg:grid grid-cols-[repeat(12,auto)] grid-rows-[repeat(6,100px)] gap-[20px] m-0 md:mb-3"
>
	<div
		*ngFor="let destination; let i = index; of: this.destinations"
		class="group my-3 mx-auto lg:visible relative w-[80vw] lg:w-full lg:my-0 h-[200px] lg:h-auto rounded-[40px] overflow-hidden cursor-pointer shadow-[0px_10px_24px_-9px_#00000070]"
		[ngClass]="'gallery__item-' + (i + 1)"
	>
		<a [routerLink]="['/wakacje-autokarem']" [queryParams]="{ locations: destination.location.id }">
			<div
				class="w-full h-full rounded-[40px] bg-center bg-cover md:group-hover:scale-110 overflow-hidden transition"
			>
				<img
					#mainDestinationsImage
					[ngSrc]="
						destination.location.multimedia[0].multimedia.url
							| optimizedImage: { mode: 'optimize', element: mainDestinationsImage }
					"
					fill
					[alt]="destination.location.name"
					class="object-cover"
				/>
			</div>
			<div class="absolute top-0 left-0 w-full h-full bg-[rgba(0,0,0,0.3)]"></div>
			<h1
				class="absolute bottom-1 uppercase lg:text-[45px] left-1/2 -translate-y-1/2 -translate-x-1/2 text-white text-3xl font-bold drop-shadow-lg transition group-hover:text-accentColor"
			>
				{{ destination.location.name }}
			</h1>
		</a>
	</div>
</div>
