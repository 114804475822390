<div
	class="contact-input customSelect"
	[ngClass]="{
		'!rounded-b-none': isOpen
	}"
	role="textbox"
	aria-label="Rozwiń listę"
	(keyup.enter)="toggleDropdown()"
	(click)="toggleDropdown()"
	[tabindex]="tabindex"
>
	<div>{{ selectedValue }}</div>
	<i class="fas fa-angle-down"></i>
</div>
<ul class="dropdownItems" *ngIf="isOpen">
	<li
		*ngFor="let option of options"
		[attr.tabindex]="0"
		(click)="selectOption(option)"
		aria-label="Temat"
		(keyup.enter)="selectOption(option)"
		class="hover:bg-[#c5c5c5] transition duration-75"
	>
		{{ option }}
	</li>
</ul>
