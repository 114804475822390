import {
	MatProduct,
	OptionalTrip,
	DescriptionTemplate,
	SellTerm,
	MatPromotion,
	PriceSettingsResult,
	SellTransport,
	Accommodation,
} from '@/_store/current-offer/current-offer.types';
import { Result } from '@/common/data.service';
import { Descriptions, LocationDescriptions } from '@/offer/offer.model';
import { EnvironmentService } from '@/services/environment/environment.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from, lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class OfferApiService {
	private readonly api: string = this.environemntService.environment.apiUrl;

	constructor(
		private readonly httpClient: HttpClient,
		private readonly environemntService: EnvironmentService,
	) {}

	getMatProduct(id: number): Observable<MatProduct> {
		return this.httpClient.get<MatProduct>(`${this.api}www/mat_product/${id}/`);
	}

	getOptionalTrip(locationIds: number[]): Observable<Result<OptionalTrip>> {
		return this.httpClient.get<Result<OptionalTrip>>(`${this.api}optional_trip/?location__id__in=${locationIds}`);
	}

	getLocation(id: number): Observable<LocationDescriptions> {
		return this.httpClient.get<LocationDescriptions>(`${this.api}location/${id}/`);
	}

	// TODO: refactor / ask if there is getLocations
	getLocations(ids: number[]): Observable<LocationDescriptions[]> {
		return from(Promise.all(ids.map((id) => lastValueFrom(this.getLocation(id)))));
	}

	getDescription(): Observable<Result<Descriptions>> {
		return this.httpClient.get<Result<Descriptions>>(`${this.api}offer/description/`);
	}

	getDescriptionTemplate(): Observable<Result<DescriptionTemplate>> {
		return this.httpClient.get<Result<DescriptionTemplate>>(`${this.api}www/description_template_www/`);
	}

	getOfferSellTerm(params: string): Observable<Result<SellTerm>> {
		return this.httpClient.get<Result<SellTerm>>(`${this.api}offer/sell_term/${params}`);
	}

	getOfferSellTransport(id: number): Observable<SellTransport[]> {
		return this.httpClient.get<SellTransport[]>(`${this.api}offer/sell_transport/${id}/`);
	}

	getAccommodation(id: number): Observable<Accommodation[]> {
		return this.httpClient.get<Accommodation[]>(`${this.api}offer/sell_accommodation/${id}/`);
	}

	getOfferPriceSettings(id: SellTerm['id']): Observable<PriceSettingsResult> {
		return this.httpClient.get<PriceSettingsResult>(`${this.api}offer/sell_price_setting/${id}/`);
	}

	getPromotions(params: string): Observable<Result<MatPromotion>> {
		return this.httpClient.get<Result<MatPromotion>>(`${this.api}www/promotions/${params}`);
	}
}
