import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { NewsletterResponse } from './newsletter.model';
import { EnvironmentService } from '@/services/environment/environment.service';

@Injectable()
export class NewsletterApiService {
	private readonly httpClient = inject(HttpClient);
	private readonly environmentService = inject(EnvironmentService);

	signUpToNewsletter(email: string): Observable<NewsletterResponse> {
		return this.httpClient.post<NewsletterResponse>(
			`${this.environmentService.environment.apiUrl}www/subscribe_to_newsletter/`,
			{
				email,
				marketing_consent: true,
			},
		);
	}
}
