import { Component, Input } from '@angular/core';
@Component({
	selector: 'app-destination-box',
	templateUrl: './destination-box.component.html',
	styleUrls: ['./destination-box.component.css'],
})
export class DestinationBoxComponent {
	// eslint-disable-next-line  @typescript-eslint/no-explicit-any
	@Input() data: any;
}
