import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class ParamsSplit {
	splitParams(url: string): string {
		const questionMarkIndex = url.indexOf('?');
		if (questionMarkIndex === -1) {
			return '';
		}
		return url.substring(questionMarkIndex + 1);
	}
}
