import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ContactFormComponent } from '../contact-form/contact-form.component';
import { DataService } from '../data.service';
import { NewsletterComponent } from '../newsletter/newsletter.component';

export interface Section {
	id: number;
	name: string;
	order: number;
	footerItem: FooterItem[];
}

interface FooterItem {
	footerSection: number;
	id: number;
	isActive: boolean;
	link: string;
	name: string;
	order: number;
}

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.css'],
	imports: [CommonModule, NewsletterComponent],
	standalone: true,
})
export class FooterComponent implements OnInit, OnDestroy {
	sections: Section[] = [];
	showError = false;
	shouldBeDisplayed = true;

	subscription: Subscription = new Subscription();

	constructor(
		private readonly dialog: MatDialog,
		private readonly api: DataService,
		@Inject(DOCUMENT) private readonly document: Document,
		private readonly router: Router,
	) {
		this.api.get(
			'www/footer_section/',
			(
				result: {
					next?: number;
					previous?: number;
					results: Section[];
				},
				error: { error: Error },
			) => {
				if (result) {
					this.sections = result.results.map((item) => {
						item.footerItem = item.footerItem
							.filter((v) => v.isActive)
							.sort((a, b) => {
								return a.order - b.order;
							});
						return item;
					});
				} else throw new Error(JSON.stringify(error.error));
			},
		);
	}

	ngOnInit(): void {
		this.subscription = this.router.events.pipe(filter((v) => v instanceof NavigationEnd)).subscribe(() => {
			this.shouldBeDisplayed = !this.document.body.classList.contains('reservation-form');
		});
	}

	ngOnDestroy(): void {
		this.subscription?.unsubscribe();
	}

	private get dialogConfig(): MatDialogConfig {
		const mediaQuery = window.matchMedia('(max-width: 767px)');
		return mediaQuery.matches
			? {
					disableClose: true,
					width: '100vw',
					maxWidth: '100vw',
					maxHeight: '100vh',
					height: '100vh',
					hasBackdrop: false,
				}
			: { disableClose: true };
	}

	openContactFormDialog(): void {
		this.dialog.open(ContactFormComponent, this.dialogConfig);
	}
}
