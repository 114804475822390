import { ActionReducer, MetaReducer } from '@ngrx/store';
import { STORAGE_STATE_KEY } from './local-storage-state-key';
import { AppState } from './app-state.interface';
import { initialState as currentOffer } from './current-offer/current-offer.reducer';
import { initialState as general } from './general/general.reducer';
import { initialState as offers } from './offers/offers.reducer';

export const metaReducers: MetaReducer<AppState>[] = [saveToStorage];

function saveToStorage(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
	return function (state, action) {
		if (typeof sessionStorage === 'undefined') {
			return { currentOffer, general, offers };
		}

		const nextState = reducer(state, action);
		sessionStorage.setItem(STORAGE_STATE_KEY, JSON.stringify(nextState));
		return nextState;
	};
}
