import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { BannerCarouselService } from '../banner-carousel/banner-carousel.service';
import { makeStateKey, StateKey, TransferState } from '@angular/core';
import { BannerImage } from '../banner-carousel/banner-carousel.component';
import { Result } from '../common/data.service';

export const IMAGES_KEY: StateKey<BannerImage[]> = makeStateKey<BannerImage[]>('banner-images');

@Injectable({
	providedIn: 'root',
})
export class MainGuard {
	constructor(
		private readonly bannerService: BannerCarouselService,
		private readonly transferState: TransferState,
	) {}

	canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.bannerService
			.loadImages()
			.then((images: Result<BannerImage>) => {
				if (images) {
					this.transferState.set(IMAGES_KEY, images.results);
					return true;
				}
				return false;
			})
			.catch(() => false);
	}
}
