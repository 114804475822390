import { CurrentOfferFacade } from '@/_store/current-offer/current-offer.facade';
import { removeLastChar } from '@/_utils/strings/remove-last-char';
import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, Input, TransferState, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Params, Router } from '@angular/router';
import { Data } from '../../offer/offer.model';
import { DateService } from '../date.service';
import { AvailableBoardsDialogComponent } from './available-boards-dialog/available-boards-dialog.component';
import { OfferBox } from './offer-box.model';

@Component({
	selector: 'app-offer-box',
	templateUrl: './offer-box.component.html',
	styleUrls: ['./offer-box.component.css'],
})
export class OfferBoxComponent {
	@Input() data = new OfferBox();
	@Input() dataToTransfer!: Data;
	@ViewChild('boardsTooltip') boardsTooltip!: ElementRef<HTMLElement>;
	fav = false;

	favToggle(): void {
		this.fav = !this.fav;
	}

	constructor(
		public router: Router,
		public dateService: DateService,
		@Inject(DOCUMENT) protected document: Document,
		private readonly dialog: MatDialog,
		private readonly transferState: TransferState,
		private readonly currentOfferFacade: CurrentOfferFacade,
	) {}

	goToOffer(): void {
		const params: Params = this.getUrlParams('object') as Params;

		this.router.navigate([`../oferta/${this.data.productId}`], {
			queryParams: Object.assign({}, params),
		});
	}

	get productId(): number {
		return this.data.productId;
	}

	getUrlParams(target: 'url' | 'object' = 'url'): Params | string {
		let roomType;
		const daterange = this.data.params.dateRange;
		if (!this.data.params.roomType) {
			roomType = '';
		} else {
			roomType = this.data.params.roomType.id;
		}
		const adults = this.data.params.adults;
		const children = this.data.params.children;
		const ownTransport = !this.data.params.stopAddres;
		const params = {
			boards: this.data.params.boards,
			promotion_catalogs: this.data.params.promotion,
			room_type: roomType,
			stops: this.data.params.stop,
			date_range: daterange,
			term_id: this.dataToTransfer.room.term.termId,
			own_transport: ownTransport,
			adults: adults,
			children: children,
			nights: this.dataToTransfer.room.term.nightsStay,
		};

		let url = '';
		for (const [key, value] of Object.entries(params)) {
			url = url.concat(`${key}=${value}&`);
		}

		url = removeLastChar(url);

		return target === 'url' ? url : params;
	}

	displayMoreFoodInfo(item: OfferBox): void {
		const iconPosition = this.boardsTooltip.nativeElement.getBoundingClientRect();

		this.dialog.open(AvailableBoardsDialogComponent, {
			hasBackdrop: true,
			position: {
				top: `${iconPosition.y + 20}px`,
				left: `${iconPosition.left - 115}px`,
			},
			data: {
				productId: item.productId,
			},
		});
	}

	protected handleButtonClick(event: Event): void {
		event.preventDefault();
		event.stopPropagation();

		this.currentOfferFacade.clearCurrentOffer();

		this.goToOffer();
	}
}
