import { NumericDictionary } from '@/_store/offers/offers.types';

export const combineNumericDictionaries = <T>(
	obj1: NumericDictionary<T>,
	obj2: NumericDictionary<T>,
): NumericDictionary<T> => {
	const combinedObj: NumericDictionary<T> = {};

	let index = 0;

	for (const key in obj1) {
		combinedObj[index] = obj1[key];
		index++;
	}

	for (const key in obj2) {
		combinedObj[index] = obj2[key];
		index++;
	}

	return combinedObj;
};
