import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { DataService } from '../../data.service';

@Component({
	selector: 'app-available-boards-dialog',
	templateUrl: './available-boards-dialog.component.html',
	styleUrls: ['./available-boards-dialog.component.css'],
})
export class AvailableBoardsDialogComponent implements OnInit {
	availableBoards$: Subject<string[]> = new Subject<string[]>();

	constructor(
		private readonly dialogRef: MatDialogRef<AvailableBoardsDialogComponent>,
		private readonly api: DataService,
		@Inject(MAT_DIALOG_DATA) private readonly data: { productId: number },
	) {}

	ngOnInit(): void {
		this.getAvailableBoards();
	}

	closeDialog(): void {
		this.dialogRef.close();
	}

	private getAvailableBoards(): void {
		this.api.get(`www/product/${this.data.productId}/boards/`, (result: string[]) => {
			if (result) {
				this.availableBoards$.next(result);
			}
		});
	}
}
