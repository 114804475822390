<div class="md:hidden pb-4 h-[auto]">
	<app-main-header [link]="link"
		><h1 class="text-lg p-3">
			<span class="text-accentColor">{{ title }} </span><span class="text-mainColor">{{ titleSuffix }}</span>
		</h1></app-main-header
	>
	<div *ngIf="!dataLoaded" class="flex flex-col justify-center items-center p-24">
		<h1 class="text-mainColor font-bold text-2xl">Wczytujemy dane, prosimy o cierpliwość :)</h1>
		<i class="fa-solid fa-sun animate-spin-slow text-accentColor text-3xl p-4"></i>
	</div>
	<div *ngIf="dataLoaded" class="flex flex-row items-center">
		<button (click)="swipePrev()" class="hidden lg:block">
			<i class="fa-solid fa-chevron-left text-accentColor text-3xl"></i>
		</button>
		<swiper [config]="swiperConfig">
			<ng-template swiperSlide *ngFor="let item of data; let i = index">
				<app-mobile-wrapper>
					<app-offer-box-mobile-horizontal
						[data]="item"
						[dataToTransfer]="offerData[i]"
						class="md:hidden"
					></app-offer-box-mobile-horizontal>
				</app-mobile-wrapper>
			</ng-template>
		</swiper>
		<button (click)="swipeNext()" class="hidden lg:block">
			<i class="fa-solid fa-chevron-right text-accentColor text-3xl"></i>
		</button>
	</div>
</div>
